import React from 'react';
import './Ramadan.css';

const Ramadan = () => {
  return (
    <div className="ramadan-container">
      <div className="content-wrapper">
        <div className="text-group">
          <h1 className="title">Ramadan at TMC</h1>
          <div className="quran-quote">
            <p className="arabic-text">شَهْرُ رَمَضَانَ الَّذِي أُنزِلَ فِيهِ الْقُرْآنُ هُدًى لِّلنَّاسِ وَبَيِّنَاتٍ مِّنَ الْهُدَىٰ وَالْفُرْقَانِ</p>
            <p className="english-text quote-translation">The month of Ramadan in which was revealed the Qur'an, a guidance for the people and clear proofs of guidance and criterion.</p>
          </div>
          <div className="hadith-section">
            <p className='english-text hadith'>Of the seven who will be under the shade of the throne of Allah on the day of judgement is "a person whose heart is attached to the masjid"</p>
            <p className='hadith-source'>- Al Bukhari and Muslim</p>
          </div>
          <div className="invitation">
            <p className='english-text'>Come join as we break our fast, pray salaah, come closer to Allah, and attach our hearts to the masjid every evening in the month of Ramadhan at the TMC masjid, N56th street.</p>
          </div>
        </div>
        <div className="video-container">
          <div className="video-wrapper">
            <video
              src="https://firebasestorage.googleapis.com/v0/b/themc-d23c7.appspot.com/o/ramadan%2FEE4BDD10FA38E2729C82840A55804A90_video_dashinit.mp4?alt=media&token=80e0a294-f09f-433a-9eb7-1d9ee7b464a3"
              width="350"
              height="500"
              controls
              loop
              autoPlay
              muted
              loading="lazy"
            />
          </div>
          <div className="video-wrapper">
            <iframe 
              src="https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2Fthemuslimconnection%2Fvideos%2F1275149473544147%2F&show_text=false&width=267&t=0&autoplay=true&mute=true" 
              width="350" 
              height="500" 
              style={{border: 'none', overflow: 'hidden'}} 
              allowFullScreen={true}
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" 
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ramadan;
