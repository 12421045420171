import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../firebaseConfig'; // Adjust the import path as needed
import { collection, getDocs } from 'firebase/firestore';
import './UmrahTrip.css'; // Create this CSS file for styles

const UmrahTrip = () => {
  const [umrahContents, setUmrahContents] = useState([]);

  useEffect(() => {
    const fetchUmrahContents = async () => {
      const querySnapshot = await getDocs(collection(db, 'umrahContent'));
      const fetchedUmrahContents = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setUmrahContents(fetchedUmrahContents);
    };

    fetchUmrahContents();
  }, []);

  const renderMedia = (content) => {
    if (content.heading.includes('2024')) {
      return (
        <video 
          controls 
          className="umrah-video"
          src="https://firebasestorage.googleapis.com/v0/b/themc-d23c7.appspot.com/o/umrah%2F2024%2Fvideo-output-1C5709E8-32EB-486C-ADF6-85325C50F42A.mov?alt=media"
        >
          Your browser does not support the video tag.
        </video>
      );
    }
    
    return (
      <div className="pictures-grid">
        {content.pictures.map((url, index) => (
          <img key={index} src={url} alt={`Umrah ${content.heading}`} className="umrah-picture" />
        ))}
      </div>
    );
  };

  return (
    <div className="umrah-container">
      <title>Umrah Trip</title>
      {umrahContents.length === 0 ? (
        <h2>Coming soon!</h2>
      ) : (
        umrahContents.map(content => (
          <div key={content.id} className="umrah-section">
            <h2 className='home-title'>{content.heading}</h2>
            {renderMedia(content)}
          </div>
        ))
      )}
    </div>
  );
};

export default UmrahTrip;
